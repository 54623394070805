<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xl="6">
        <b-card header-tag="header">
          <div slot="header"><i class="icon-bulb"></i> Suggestion settings</div>
          <b-card-body>
            <div v-if="loading">
              <div class="sk-circle-fade float-left">
                <div class="sk-grid-cube"></div>
                <div class="sk-grid-cube"></div>
                <div class="sk-grid-cube"></div>
                <div class="sk-grid-cube"></div>
                <div class="sk-grid-cube"></div>
                <div class="sk-grid-cube"></div>
                <div class="sk-grid-cube"></div>
                <div class="sk-grid-cube"></div>
                <div class="sk-grid-cube"></div>
              </div>
              <h2 class="float-left ml-4">Loading suggestions...</h2>
            </div>
            <div v-else>
              <div v-if="has_suggestions === true">
                <b-row>
                  <div>
                    <h4 class="smalltitle">Vote color intensity</h4>
                    <p>
                      Sets the number of up/downvotes required for the log
                      entry's color to be fully red/green. Between 0 (always
                      full) and 100 inclusive
                    </p>
                    <b-form-group>
                      <b-input-group>
                        <b-form-input
                          type="number"
                          placeholder="3"
                          autocomplete="3"
                          v-model="threshold"
                          min="0"
                          max="100"
                          maxlength="2"
                          v-on:keydown="handleNonDecimalInput"
                          @keyup.enter.native="handlethreshold"
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <hr />
                    <h6 class="tinytitle">
                      Send DM on Decision
                      <c-switch
                        color="info"
                        label
                        variant="pill"
                        v-bind="labelIcon"
                        v-model="direct_message"
                        id="nsfw_switch"
                        class="float-right"
                      />
                    </h6>
                    <label
                      class="col-form-label"
                      for="nsfw_switch"
                      style="color:#a8b6bd"
                      >Send a message to the author of a suggestion after a
                      decision has been made</label
                    >
                    <hr />
                    <h6 class="tinytitle">
                      Edit Instead of Reposting
                      <c-switch
                        color="info"
                        label
                        variant="pill"
                        v-bind="labelIcon"
                        v-model="edit"
                        id="jump_switch"
                        class="float-right"
                      />
                    </h6>
                    <label
                      class="col-form-label"
                      for="jump_switch"
                      style="color:#a8b6bd"
                      >Edits the original suggestion message instead of
                      reposting it. If a move channel has been set, it will be
                      sent there AND have the original message be edited.</label
                    >
                    <hr />
                    <h6 class="tinytitle">
                      Anonymous Mode
                      <c-switch
                        color="info"
                        label
                        variant="pill"
                        v-bind="labelIcon"
                        v-model="anon"
                        id="self_switch"
                        class="float-right"
                      />
                    </h6>
                    <label
                      class="col-form-label"
                      for="self_switch"
                      style="color:#a8b6bd"
                      >Hides the name and avatar of the person who made the
                      suggestion.</label
                    >
                    <hr />
                    <b-form-group>
                      <label for="suggestion_channel" class="teenytinytitle"
                        >Suggestion channel</label
                      >
                      <multiselect
                        v-model="suggestion_channel"
                        id="suggestion_channel"
                        track-by="id"
                        label="name"
                        placeholder="Please select a channel"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        :options="groupedChannels"
                        :allow-empty="false"
                        group-values="channels"
                        group-label="category"
                        :group-select="false"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <font-awesome-icon
                              style="color:#72767d"
                              icon="hashtag"
                            />
                            <span class="option__title ml-2">{{
                              props.option.name
                            }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">
                              <font-awesome-icon
                                style="color:#72767d"
                                :icon="
                                  !props.option['$isLabel']
                                    ? 'hashtag'
                                    : 'chevron-down'
                                "
                              />
                              {{
                                props.option.name ||
                                  props.option.$groupLabel.name
                              }}
                            </span>
                          </div>
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group>
                      <label for="decision_channel" class="teenytinytitle"
                        >Decision channel</label
                      >
                      <multiselect
                        v-model="decision_channel"
                        id="suggestion_channel"
                        track-by="id"
                        label="name"
                        placeholder="No decision channel selected"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        :options="groupedChannels"
                        :allow-empty="false"
                        group-values="channels"
                        group-label="category"
                        :group-select="false"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <font-awesome-icon
                              style="color:#72767d"
                              icon="hashtag"
                            />
                            <span class="option__title ml-2">{{
                              props.option.name
                            }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">
                              <font-awesome-icon
                                style="color:#72767d"
                                :icon="
                                  !props.option['$isLabel']
                                    ? 'hashtag'
                                    : 'chevron-down'
                                "
                              />
                              {{
                                props.option.name ||
                                  props.option.$groupLabel.name
                              }}
                            </span>
                          </div>
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group>
                      <label for="limit_channel" class="teenytinytitle"
                        >Restrict suggestions to</label
                      >
                      <multiselect
                        v-model="limit_channel"
                        id="suggestion_channel"
                        track-by="id"
                        label="name"
                        placeholder="Do not restrict"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        :options="groupedChannels"
                        :allow-empty="false"
                        group-values="channels"
                        group-label="category"
                        :group-select="false"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <font-awesome-icon
                              style="color:#72767d"
                              icon="hashtag"
                            />
                            <span class="option__title ml-2">{{
                              props.option.name
                            }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">
                              <font-awesome-icon
                                style="color:#72767d"
                                :icon="
                                  !props.option['$isLabel']
                                    ? 'hashtag'
                                    : 'chevron-down'
                                "
                              />
                              {{
                                props.option.name ||
                                  props.option.$groupLabel.name
                              }}
                            </span>
                          </div>
                        </template>
                      </multiselect>
                    </b-form-group>
                  </div>
                </b-row>
              </div>

              <div v-else>
                <h4 class="smalltitle">No suggestion channel found</h4>
                <h6>
                  A suggestion channel is required for all of the commands to
                  work. You can either select an already existing channel or
                  have a new one created for you with the channel overwrites
                  already created.
                </h6>
                <b-form-group>
                  <b-input-group>
                    <div class="input-group-prepend">
                      <div class="input-group-text">#</div>
                    </div>
                    <b-form-input
                      type="text"
                      pattern="[a-z0-9-_]{1,32}"
                      placeholder="Enter the name of the channel you want created"
                      v-model="suggestions_name"
                    ></b-form-input>
                    <!-- Attach Right button -->
                    <b-input-group-append>
                      <b-button
                        v-on:click="create_suggestions"
                        variant="primary"
                        >Create</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group>
                    <b-form-select
                      id="suggestion_channel_select_thing"
                      v-model="suggestion_channel"
                      value="Please select"
                    >
                      <option :value="null"
                        >Select a channel from this dropdown</option
                      >
                      <option
                        v-for="c in cheanedChannels"
                        :key="c.position"
                        :value="c.id"
                        >#{{ c.name }}</option
                      >
                    </b-form-select>
                    <b-input-group-append>
                      <b-button
                        v-on:click="create_suggestions_from"
                        variant="primary"
                        >Select</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </b-card-body>
          <div slot="footer" v-if="has_suggestions">
            <b-button
              class="float-right"
              variant="primary"
              v-on:click="patch_suggestions"
              >Save</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

Vue.use(VueNotifications, options);

export default {
  name: "Suggestions",
  components: {
    Callout,
    cSwitch,
    Multiselect
  },
  data: function() {
    return {
      selected: "Month",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      direct_message: false,
      edit: false,
      anon: false,
      threshold: 5,
      limit_channel: null,
      decision_channel: null,
      suggestion_channel: null,
      channels: [],
      suggestions_name: "",
      loading: true,

      has_suggestions: true
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Setting applied"
    },
    showInfoMsg: {
      type: VueNotifications.types.info,
      title: "No changes",
      message: "That's your current threshold."
    },
    showWarnMsg: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "Pick a number between 1 and 100."
    },
    showErrorMsg: {
      type: VueNotifications.types.error,
      title: "Uh-oh",
      message: "Pick a number between 1 and 100."
    }
  },
  methods: {
    handlethreshold(v) {
      if (
        !(this.threshold < 0 || this.threshold > 100) &&
        this.threshold != this.current_threshold
      ) {
        this.current_threshold = this.threshold;
        this.handleChange("threshold", this.threshold);
      } else if (this.threshold == this.current_threshold) {
        this.showInfoMsg();
      } else {
        this.showWarnMsg();
      }
    },
    patch_suggestions() {
      if (!this.suggestion_channel || !this.suggestion_channel.id) {
        this.showWarnMsg({
          message:
            "Please select a suggestion channel, it does not work without one."
        });
        return;
      }
      this.axios
        .patch(
          "/api/v1/servers/" + this.$route.params.guild_id + "/suggestions",
          {
            announcement_channel: this.suggestion_channel.id,
            submit_channel: this.limit_channel ? this.limit_channel.id : null,
            move_channel: this.decision_channel
              ? this.decision_channel.id
              : null,
            threshold: this.threshold || 3,
            anon: this.anon,
            dm: this.direct_message,
            edit_message: this.edit
          }
        )
        .then(this.showSuccessMsg({ message: "Channels updated" }));
    },
    handleChange(e, v) {
      if (this.ignore_patch) {
        return;
      }
      this.axios
        .patch(
          "/api/v1/servers/" + this.$route.params.guild_id + "/suggestions",
          { [e]: v }
        )
        .then(this.showSuccessMsg());
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    updatePage() {
      this.axios
        .get("/api/v1/servers/" + this.$route.params.guild_id + "/channels")
        .then(r => {
          this.channels = r.data;
        });
      this.axios
        .get("/api/v1/servers/" + this.$route.params.guild_id + "/suggestions")
        .then(r => {
          if (Object.keys(r.data).length !== 0) {
            this.suggestion_channel = this.channels.find(
              c => c.id === r.data.announcement_channel
            );
          }
        });
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    create_suggestions() {
      this.axios
        .post(
          "/api/v1/servers/" + this.$route.params.guild_id + "/suggestions",
          {
            name: this.suggestions_name,
            author: this.$store.state.discord_user.id
          }
        )
        .then(() => {
          this.has_suggestions = true;
          this.showSuccessMsg({ message: "Channel Created" });
          setTimeout(() => {
            this.updatePage();
          }, 2000);
        });
    },
    create_suggestions_from() {
      this.axios
        .put(
          "/api/v1/servers/" + this.$route.params.guild_id + "/suggestions",
          {
            announcement_channel: this.suggestion_channel
          }
        )
        .then(r => {
          this.has_suggestions = true;
          this.suggestion_channel = this.channels.find(
            c => c.id === this.suggestion_channel
          );
          this.showSuccessMsg({ message: "Channel Selected" });
        });
    }
  },
  computed: {
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    },
    cheanedChannels() {
      return this.channels
        .filter(c => c.type == 0)
        .sort((a, b) => a.position - b.position);
    }
  },
  created() {
    this.$Progress.start();
    this.axios
      .get(
        "/api/v1/servers/" +
          this.$route.params.guild_id +
          "/channels?categories=true"
      )
      .then(r => {
        this.channels = r.data;
        this.$Progress.increase();
      });
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/suggestions")
      .then(r => {
        this.loading = false;
        if (Object.keys(r.data).length === 0) {
          this.has_suggestions = false;
        } else {
          this.direct_message = r.data.dm;
          this.edit = r.data.edit_message;
          this.anon = r.data.anon;
          this.threshold = r.data.threshold;
          let submit_chn = r.data.submit_channel;
          this.limit_channel =
            this.channels.find(c => c.id === submit_chn) || null;
          let move_chn = r.data.move_channel;
          this.decision_channel =
            this.channels.find(c => c.id === move_chn) || null;
          let announce_chn = r.data.announcement_channel;
          this.suggestion_channel =
            this.channels.find(c => c.id === announce_chn) || null;
        }
        this.$Progress.finish();
      });
  }
};
</script>
